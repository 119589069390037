<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useModalStore } from '@horizon/library/stores';
import { overrideStyles, getTestId } from '@horizon/library/utils';
import * as components from '~~/.nuxt/uiLibFacade'; // generated file containing all used components

const modalStore = useModalStore();
const { component, isDismissable, blur } = storeToRefs(modalStore);
const asyncComponentInstance = shallowRef<Component | undefined>(undefined);
const testID = ref<string>('modal');
const backgroundurl = computed(() => {
  return component.value?.data?.backgroundURL
    ? `url(${component.value?.data.backgroundURL})`
    : undefined;
});

watchEffect(() => {
  asyncComponentInstance.value = components[modalStore.componentName as keyof typeof components];
  testID.value = component.value ? getTestId(component.value) : 'Modal';
});

function clearModal() {
  if (!isDismissable.value) {
    return;
  }

  modalStore.clearModal();
}
</script>

<template>
  <template v-if="asyncComponentInstance && component">
    <Teleport to="body">
      <div v-if="backgroundurl" class="overlay fixed inset-0 z-40 bg-cover bg-center"></div>
      <div v-if="blur" class="fixed inset-0 z-40 bg-cover bg-center backdrop-blur"></div>
      <div class="bg-modal-backdrop/70 fixed inset-0 z-40"></div>
      <div
        class="fixed inset-0 z-50 grid place-items-center"
        :data-testid="testID + '/CloseButton'"
        @click.self="clearModal()"
      >
        <component
          :is="asyncComponentInstance"
          v-bind="component"
          :style="overrideStyles(component.overrides)"
          :data-testid="testID"
          :test-id="testID"
        ></component>
      </div>
    </Teleport>
  </template>
</template>

<style scoped>
.overlay {
  background-image: v-bind('backgroundurl');
}
</style>

