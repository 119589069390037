<script setup lang="ts">
import { useSiteConfigStore, useModalStore, useAuthStore } from '@horizon/library/stores';
import type {
  Page,
  PreviewerLoadedMessage,
  PreviewerReadyMessage,
  PreviewerUpdateMessage
} from '@horizon/library/types';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import ModalRenderer from '~/components/ModalRenderer.vue';
import { overrideStyles } from '@horizon/library/utils';
import { buildHeadContent } from '~/utils/pageLoader';
import * as components from '~~/.nuxt/uiLibFacade'; // generated file containing all used components
import CookieBanner from '~/components/CookieBanner.vue';
import { EModal } from '@horizon/library/types';
import type { Component } from 'vue';

const runtimeConfig = useRuntimeConfig();
const route = useRoute();
const siteConfigStore = useSiteConfigStore();
const { siteConfig, pages } = storeToRefs(siteConfigStore);
const modalStore = useModalStore();
const authStore = useAuthStore();
const { isAuthorized } = storeToRefs(authStore);

// Do not use currentPage from the site config store, otherwise this will cause problems during page navigation.
// The old page will start rendering components of the new page, causing double calls.
const currentPage = ref<Page | undefined>();

function loadTheme() {
  const theme = siteConfig.value?.theme;
  const siteSettings = siteConfig.value?.siteSettings;
  if (!theme || !siteSettings) return;

  const headContent = buildHeadContent(siteSettings, theme, currentPage.value);
  useHead(headContent);
}

function sendLoadedMessageToParent() {
  if (window.top === window.self) return;
  const message: PreviewerLoadedMessage = {
    name: 'horizon-previewer-loaded'
  };

  window.top?.postMessage(message, runtimeConfig.public.editorUrl as string);
}

function sendReadyMessageToParent() {
  if (window.top === window.self) return;
  const message: PreviewerReadyMessage = {
    name: 'horizon-previewer-ready'
  };

  window.top?.postMessage(message, runtimeConfig.public.editorUrl as string);
}

function iframeMessageListener(event: MessageEvent<PreviewerUpdateMessage>) {
  if (runtimeConfig.public.isInPreviewer) {
    const shouldRejectMessage =
      event.origin !== runtimeConfig.public.editorUrl ||
      event.data?.name !== 'horizon-previewer-update' ||
      !event.data?.newSiteConfig;
    if (shouldRejectMessage) return;

    siteConfigStore.setConfig(event.data.newSiteConfig);
    currentPage.value = getPageFromRoutePath(pages.value, route.path);
    loadTheme();

    sendReadyMessageToParent();
  }
}

currentPage.value = getPageFromRoutePath(pages.value, route.path);

loadTheme();

onBeforeMount(() => {
  if (isAuthorized.value && route.path === '/credits' && currentPage.value === undefined) {
    navigateTo('/');
    setTimeout(() => {
      modalStore.showModalComponent(EModal.insufficientCreditsModalComponent);
    }, 100);
  }
});

onMounted(async () => {
  if (runtimeConfig.public.isInPreviewer) {
    window.addEventListener('message', iframeMessageListener);
    sendLoadedMessageToParent();
  }
});

onBeforeUnmount(() => {
  if (runtimeConfig.public.isInPreviewer) {
    window.removeEventListener('message', iframeMessageListener);
  }
});

provide<Record<keyof typeof components, Component>>('components', components);
</script>

<template>
  <div>
    <div
      v-if="currentPage"
      data-scope="page"
      :data-page="currentPage.name"
      class="flex min-h-screen flex-col"
      :style="overrideStyles(currentPage.pageOverrides, true)"
    >
      <!-- eslint-disable-next-line vue/valid-v-for -->
      <CustomComponent
        v-for="component in currentPage.components"
        :component-data="component"
      ></CustomComponent>
    </div>

    <CookieBanner></CookieBanner>

    <ClientOnly>
      <ModalRenderer></ModalRenderer>
    </ClientOnly>
  </div>
</template>

