<script setup lang="ts">
import type { components } from '@horizon/library/schemas/collectconsent';
import { useAuthStore, useSiteConfigStore } from '@horizon/library/stores';
import { useCookieBannerState } from '@horizon/library/utils/consentChecker';
import TextRenderer from '@horizon/library/components/LegalText/TextRenderer.vue';

const { $translate } = useNuxtApp();

const authStore = useAuthStore();

const collectConsentApi = createCCFetchClient();

const siteConfigStore = useSiteConfigStore();

const state = ref<'hidden' | 'idle' | 'fetching' | 'error'>('hidden');

type ActiveConsentSegmentVersion =
  components['schemas']['contracts.LatestActiveConsentSegmentVersionResponse'];
const necessaryCookieData = ref<ActiveConsentSegmentVersion | null>(null);
const analyticalCookieData = ref<ActiveConsentSegmentVersion | null>(null);

async function load() {
  if (authStore.isAuthorized) return;

  const cookieState = useCookieBannerState().value;
  if (cookieState) return;

  const locale = siteConfigStore.localeCode;
  if (!locale) return;

  const [necessaryCookieResponse, analyticalCookieResponse] = await Promise.all([
    collectConsentApi('/guest/locale/{locale}/consent/{key}/active', {
      method: 'POST',
      path: {
        key: 'cookieStatement::necessaryCookies',
        locale
      }
    }),
    collectConsentApi('/guest/locale/{locale}/consent/{key}/active', {
      method: 'POST',
      path: {
        key: 'cookieStatement::analyticalCookies',
        locale
      }
    })
  ]);

  necessaryCookieData.value = replaceContentVariables(necessaryCookieResponse.data);
  analyticalCookieData.value = replaceContentVariables(analyticalCookieResponse.data);

  if (necessaryCookieData.value || analyticalCookieData.value) {
    state.value = 'idle';
  }
}

await load();

function replaceContentVariables(
  data: ActiveConsentSegmentVersion | null
): ActiveConsentSegmentVersion | null {
  if (!data) return null;

  const cookieStatementPath = siteConfigStore.pages?.find((x) => x.key === 'cookieStatement')?.path;

  const replacedContent = data.content
    .replaceAll('{{cookieStatementPath}}', cookieStatementPath ?? '/cookiepolicy')
    .replaceAll(
      encodeURIComponent('{{cookieStatementPath}}'),
      cookieStatementPath ?? '/cookiepolicy'
    );

  return {
    ...data,
    content: replacedContent
  };
}

async function decline() {
  const exitWebsiteUrl = siteConfigStore.siteSettings?.exitWebsiteUrl ?? 'https://google.com';
  window.location.href = exitWebsiteUrl;
}

async function acceptNecessary() {
  const versionIDs: string[] = [];
  if (necessaryCookieData.value) {
    versionIDs.push(necessaryCookieData.value.versionID);
  }
  await accept(versionIDs);
}

async function acceptAll() {
  const versionIDs: string[] = [];
  if (necessaryCookieData.value) {
    versionIDs.push(necessaryCookieData.value.versionID);
  }
  if (analyticalCookieData.value) {
    versionIDs.push(analyticalCookieData.value.versionID);
  }
  await accept(versionIDs);
}

async function accept(consentSegmentVersionIDs: string[]) {
  state.value = 'fetching';

  const fingerprint = crypto.randomUUID();

  const { error } = await collectConsentApi('/guest/consent', {
    method: 'POST',
    body: {
      fingerprint,
      consentSegmentVersionIDs,
      type: 'banner'
    }
  });

  if (error) {
    state.value = 'error';
    return;
  }

  useCookieBannerState().value = {
    fingerprint,
    consentSegmentVersionIDs
  };

  state.value = 'hidden';
}
const testID = 'CookieBanner';
</script>

<template>
  <div
    v-if="state !== 'hidden'"
    class="bg-background border-primary fixed bottom-0 z-50 w-full border-t-2 py-4"
  >
    <div class="container">
      <p v-if="state === 'error'" class="bg-danger mb-4 p-4 text-white">
        {{ $translate('Could not accept cookies, please reload the page and try again.') }}
      </p>

      <h1 class="text-primary mb-2 text-lg font-bold">
        {{ $translate('By accepting cookies we can improve your user experience on our website.') }}
      </h1>

      <div class="flex flex-col gap-4 sm:flex-row">
        <div>
          <ul>
            <li v-if="necessaryCookieData">
              <TextRenderer
                class="mb-2 text-sm"
                :data="{ text: necessaryCookieData.content }"
                :test-id="null"
              ></TextRenderer>
            </li>
            <li v-if="analyticalCookieData">
              <TextRenderer
                class="mb-2 text-sm"
                :data="{ text: analyticalCookieData.content }"
                :test-id="null"
              ></TextRenderer>
            </li>
          </ul>

          <button
            class="bg-none text-sm underline"
            :data-testid="testID + 'DeclineButton'"
            @click="decline()"
          >
            {{ $translate('Decline cookies') }}
          </button>
        </div>

        <div class="flex flex-col gap-2 text-sm">
          <button
            v-if="necessaryCookieData"
            class="border-btn-primary-bg hover:border-btn-primary-hover-bg w-full whitespace-nowrap border-2 bg-none px-4 py-2 hover:scale-105 disabled:pointer-events-none disabled:border-gray-500"
            :disabled="state !== 'idle'"
            :data-testid="testID + 'AcceptNecessaryButton'"
            @click="acceptNecessary()"
          >
            {{ $translate('Accept necessary cookies') }}
          </button>

          <button
            v-if="necessaryCookieData || analyticalCookieData"
            class="bg-btn-primary-bg hover:bg-btn-primary-hover-bg w-full whitespace-nowrap px-4 py-2 hover:scale-105 disabled:pointer-events-none disabled:bg-gray-500"
            :disabled="state !== 'idle'"
            :data-testid="testID + 'AcceptAllButton'"
            @click="acceptAll()"
          >
            {{ $translate('Accept all cookies') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

